@font-face {
  font-family: 'font1';
  src: url('./assets/fonts/Nunito-SemiBold.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'font2';
  src: url('./assets/fonts/Baloo-Regular-400.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'font3';
  src: url('./assets/fonts/Nunito-Regular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'font4';
  src: url('./assets/fonts/Nunito-Bold.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
:root {
  --primary-color: rgba(38, 170, 118, 1);
  --primary-text: rgb(23, 23, 23);
  --secondary-text: rgba(23, 23, 23, 0.7);
  --secondary-text-alt: rgba(255, 255, 255, 0.8);
  --background-color: rgba(255, 255, 255, 1);
  --error-color: #cc0000;
  --success-bg: rgba(38, 170, 118, 1);
  --error-bg: rgba(250, 120, 121, 1);
  font-size: 13px;
}
@media (min-width: 600px) {
  :root {
    font-size: 15px;
  }
}
@media (min-width: 767px) {
  :root {
    font-size: 16px;
  }
}

html,
body {
  font-family: font1;
  overflow-x: hidden;
  background-color: var(--background-color);
  box-sizing: border-box;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.fullWidth {
  width: 100%;
}

.changePasswordPush {
  margin-top: 40px;
}

.right {
  text-align: right !important;
}

.left {
  text-align: left !important;
}

.center {
  text-align: center;
}

.centerImage {
  margin: auto;
  display: block;
}
.block {
  display: block;
}

.floatLeft {
  float: left;
}

.loading-indicator:after {
  content: url('./assets/images/loading.gif');
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 8889;
  color: white;
  text-align: center;
  /* font-weight: bold;
  font-size: 1rem;
  font-family: font2; */
}

.loading-indicator:before {
  content: '';
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 8888;
}

.swal2-validation-message {
  width: 100%;
  text-align: center;
  margin: auto !important;
}

.font2 {
  font-family: font2;
}

.font3 {
  font-family: font3;
}

.font4 {
  font-family: font4;
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }

  to {
    transform: translateY(0rem);
  }
}

.floatRight {
  float: right;
}

*,
*:hover,
*:active,
*:visited {
  outline: none !important;
}

a {
  text-decoration: none;
  background-color: inherit !important;
}

a:hover {
  text-decoration: none !important;
}

.bottomBorder {
  border-bottom: 0.5px solid darkslategray;
  padding-bottom: 18px;
}
.horizontal-padding {
  padding: 0 50px 0 30px;
}

.pointer {
  cursor: pointer;
}

.general-shadow {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.hover-style {
  text-decoration: underline;
}
.hover-style:hover {
  text-decoration: none;
}
.loader-medium {
  width: 24px;
  height: 24px;
  background-color: inherit;
  border-radius: 50%;
  border-top: 2px solid var(--primary-color);
  border-left: 2px solid var(--primary-color);
  border-right: 2px solid var(--primary-color);
  border-bottom: 2px solid transparent;
  display: inline-block;
  animation: rotate 0.6s linear infinite;
}
.loader-small {
  width: 16px;
  height: 16px;
  background-color: transparent;
  border-radius: 50%;
  border-top: 2px solid var(--primary-color);
  border-left: 2px solid var(--primary-color);
  border-right: 2px solid var(--primary-color);
  border-bottom: 2px solid transparent;
  animation: rotate 0.6s linear infinite;
  display: inline-block;
  margin: 0 !important;
  padding: 0;
  line-height: 0;
}

.loader-dark {
  border-top: 2px solid rgba(48, 52, 61, 1);
  border-left: 2px solid rgba(48, 52, 61, 1);
  border-right: 2px solid rgba(48, 52, 61, 1);
  border-bottom: 2px solid var(--background-color);
}
.title-text {
  font-family: font2 !important;
}

.general-shadow {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.body-padding {
  padding: 20px 15px;
}
.page-padding {
  padding: 20px;
}
@media screen and (min-width: 600px) {
  .body-padding {
    padding: 20px 37px 20px 37px;
    padding: 30px 30px;
  }

  .page-padding {
    padding: 30px;
  }
}
@media screen and (min-width: 768px) {
  .body-padding {
    padding: 20px 37px 20px 37px;
    padding: 30px 40px;
  }

  .page-padding {
    padding: 40px;
  }
}
@media screen and (min-width: 960px) {
  .body-padding {
    padding: 20px 37px 20px 37px;
    padding: 30px 60px;
  }

  .page-padding {
    padding: 50px;
  }
}
/* .two-line-truncate:after {
  content: '...';
  position: absolute;
  right: 0;
  bottom: 0;
} */
