#toolbar a {
  color: var(--primary-text);
  transition: color 0.2s ease;
}
#toolbar a:hover {
  color: var(--primary-color);
}

#toolbar .c-nav-link.active {
  color: var(--primary-color);
}

#toolbar .mini-menu {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
}

#toolbar .side-menu {
  position: fixed;
  width: 250px;
  right: 0;
  top: 0;
  height: 100vh;
  background-color: white;
  padding: 50px 10px 20px 10px;
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 0.2s ease;
}

#toolbar .side-menu.show {
  transform: translateX(0);
  box-shadow: -3px 0 5px rgba(0, 0, 0, 0.1);
}

#toolbar .side-menu .nav {
  padding: 5px 10px;
}
#toolbar .side-menu .nav.active {
  background-color: #f5f5f5 !important;
  color: var(--primary-text) !important;
}

#toolbar .search input {
  transform: translateY(-80px);
  transition: transform 0.4s ease;
}
#toolbar .search:hover input {
  transform: translateY(0);
}
#toolbar .search .search-results {
  background-color: white;
  transition: transform 0.4s ease;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: -15px;
  width: 300px;
  max-width: 70vw;
  top: 180%;
  border-radius: 5px;
  padding: 15px 10px;
  visibility: hidden;
  opacity: 0.2;
  transform: translateY(30px);
  transition: all 0.4s ease;
  z-index: 50;
  max-height: 80vh;
  overflow-y: auto
}
#toolbar .search:hover .search-results {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

#toolbar .mini-categories {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
}
