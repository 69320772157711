#article-card .img-container {
  width: 100%;
  padding-top: 70%;
  position: relative;
}
#article-card .img-container img,
#article-card .img-container video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}

#read-more-arrow {
  margin-left: 13px;
}

#buttonGreen {
  color: #26aa76;
  background: none;
  border: 0;
  padding-left: 0;
  text-decoration: underline;
}

#buttonGreen:hover {
  color: #247053;
  fill: #247053;
}
