#button {
  border: none;
  padding: 0.5em 2em;
  position: relative;
}

#button.loading .loader {
  display: block;
}
#button.loading .children {
  opacity: 0;
}

#button.disabled {
  background-color: rgba(199, 199, 199, 0.47) !important;
  color: rgba(79, 78, 78, 0.78) !important;
}

#button .loader {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border-radius: 50%;
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-right: 2px solid white;
  position: absolute;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
  display: none;
  animation: rotate 0.6s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

#action-button {
  border: none;
  border-radius: 5px;
  padding: 4px 6px;
}

#action-button.add {
  background: linear-gradient(
    246.17deg,
    #26aa76 34.33%,
    rgba(38, 170, 118, 0) 215.58%
  );
}
#action-button.delete {
  background: linear-gradient(
    247.04deg,
    #ff0000 34.36%,
    rgba(255, 0, 0, 0.08) 130.84%
  );
}
