#social-icons button:not(:first-child) {
  margin-top: 13px;
}

#social-icons button {
  border: none;
  background: none;
}

.category-name {
  color: #26aa76;
}

#home-page .img-backdrop {
  width: 100%;
  display: flex;
}
#home-page .img-backdrop video {
  width: 100%;
  height: auto;
}
#home-page .img-backdrop .img-container {
  width: 100%;
  padding-top: 50%;
  position: relative;
}
#home-page .img-backdrop .img-container img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 960px) {
  #home-page .img-backdrop {
    width: 80%;
  }
}
