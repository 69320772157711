#footer .info {
  display: grid;
  background-color: #000;
  color: var(--secondary-text-alt);
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 2em 1em;
}
/* #footer .info {
  display: flex;
  background-color: #000;
  color: var(--secondary-text-alt);
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2em 1em;
}
#footer .info *:first-child{
  max-width: 400px
} */
@media (min-width: 1100px) {
  #footer .info {
    padding: 80px;
  }
}

#footer .info a {
  color: var(--secondary-text-alt);
}
