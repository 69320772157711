#pagination .page-btn {
    background-color: white;
    margin: 7px;
    min-width: 2em;
    height: 2em;
    border: none;
    border-radius: 0.25em;
    /* overflow: hidden !important; */
  }
  
  #pagination .page-btn.active {
    background-color: var(--primary-color);
    color: white;
  }
  