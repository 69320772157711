#article .img-backdrop {
  width: 100%;
  display: flex;
}
#article .img-backdrop .img-container {
  width: 100%;
  padding-top: 60%;
  position: relative;
}
#article .img-backdrop .img-container img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#article #article-heading{
  width: 80%;
  margin: 0 auto;
}
#article .article-body .article-content figure, #article .article-body .article-content img{
  max-width: 100% !important;
  width: auto !important;
  object-fit: contain;
  height: auto !important
}
#article .article-body .article-content img{
  margin-bottom: 1em;
}
#article .article-body .article-content *{
  font-size: 13px !important;
}
#article .img-backdrop video {
  width: 100%;
  height: auto;
}
@media (min-width: 600px) {
  #article .article-body .article-content *{
    font-size: 15px !important;
  }
  #article #article-heading{
    width: 65%;
    margin: 0 auto
  }
}

@media (min-width: 767px){
  #article .article-body .article-content *{
    font-size: 16px !important;
  }
}

@media (min-width: 960px) {
  #article .img-backdrop {
    width: 80%;
  }
}

.category-name {
  color: #26aa76;
  font-size: larger;
}

.box {
  position: relative;
}

/* #article .social-icons{
} */

#border {
  border-left: 20px solid #f5f5f5;
  border-right: 20px solid #f5f5f5;
  border-bottom: 20px solid #f5f5f5;
  top: 50%;
  position: absolute;
  bottom: 0;
}
