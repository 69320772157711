#alert {
    color: white;
    display: flex;
    justify-content: space-between;
    font-size: 85%;
    padding: 12px;
    border-radius: 5px;
    position: relative;
    padding-right: 40px;
  }
  
  #alert.success {
    background-color: rgba(38,170,118,1);
  }
  #alert.error {
    background-color: rgba(250,120,121,1);
  }
  
  #alert button{
      border:none;
      background-color: inherit;
      position: absolute;
      top: 5px;
      right:5px;
  }
  